.MuiFormControl-root {
    p {
        &.Mui-error {
            position: absolute;
            font-family: "Roboto", sans-serif;
            left: 0;
            font-weight: 400;
            bottom: -23px;
            font-size: 13px;
        }
    }
    &.login-form {
        .MuiFormLabel-root {
            left: 14px;
            top: 6px;
            font-size: 14px;
            &.MuiInputLabel-shrink {
                top: 0px;
                left: 2px;
                font-size: 16px;
                background: #fff0;
                padding: 0 0px;
            }
        }
        .MuiInputBase-root {
            border-radius: 13px;
            .MuiInputBase-input {
                padding: 20px 25px;
            }
            .MuiOutlinedInput-notchedOutline {
            }
        }
    }
    &.common-input {
        .MuiFormLabel-root {
            &.MuiInputLabel-shrink {
                background: #fff;
                padding: 0px 3px;
            }
        }
        .MuiInputBase-root {
            border-radius: 10px;
            .MuiInputBase-input {
            }
            .MuiOutlinedInput-notchedOutline {
            }
        }
    }
}

.header-search-input {
    input {
        border-radius: 30px;
        padding-left: 20px;
    }
}

input[type=password]::-ms-reveal,
input[type=password]::-ms-clear
{
    display: none;
}

.datepicker-form {
    position: relative;
    button {
        border: 1px solid #ccc;
        min-height: 38px;
        font-size: 0.75rem;
        width: 100%;
        background: #fff;
        border-radius: 0.25rem;
        text-align: left;
        padding: 10px 13px;
        font-weight: 500;
        color: #4d556a;
        &:hover {
            border: 1px solid #a9a9a9;
        }
    }
    .calendar-icon {
        position: absolute;
        right: 10px;
        top: 10px;
        font-size: 18px;
        color: #4d556a;
        cursor: pointer;
        pointer-events: none;
    }
}
.css-b62m3t-container {
    .css-13cymwt-control {
        .css-1fdsijx-ValueContainer {
            .css-1jqq78o-placeholder {
                color: #aeaeae;
                font-size: 0.875rem;
            }
        }
    }
    .css-1nmdiq5-menu {
        z-index: 9999;
    }
}
.css-1jqq78o-placeholder {
    font-size: 0.8rem;
}